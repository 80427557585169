import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogConfig, NbDialogRef, NbDialogService, NbThemeService, NbToastrService } from '@nebular/theme';
import { AppModule } from '../../../../app.module';
import { ApiService } from '../../../../services/api.service';
import { RootServices } from '../../../../services/root.services';
import { CommonService } from '../../../../services/common.service';
// import { PurchaseModule } from '../../purchase.module';
import { CollaboratorOpportunityFormComponent } from '../opportunity-form/opportunity-form.component';
import { CollaboratorOpportunityPrintComponent } from '../opportunity-print/opportunity-print.component';
import { AgGridDataManagerListComponent } from '../../../../lib/data-manager/ag-grid-data-manger-list.component';
import { DatePipe } from '@angular/common';
import { DialogFormComponent } from '../../../dialog/dialog-form/dialog-form.component';
import { FormGroup } from '@angular/forms';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { AgDateCellRenderer } from '../../../../lib/custom-element/ag-list/cell/date.component';
import { agMakeTagsColDef } from '../../../../lib/custom-element/ag-list/column-define/tags.define';
import { AgSelect2Filter } from '../../../../lib/custom-element/ag-list/filter/select2.component.filter';
import { agMakeCurrencyColDef } from '../../../../lib/custom-element/ag-list/column-define/currency.define';
import { agMakeStateColDef } from '../../../../lib/custom-element/ag-list/column-define/state.define';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { ColDef, IGetRowsParams } from '@ag-grid-community/core';
import { CollaboratorOpportunityDetailModel, CollaboratorOpportunityModel } from '../../../../models/collaborator.model';
import { ProcessMap } from '../../../../models/process-map.model';
import { CollaboratorService } from '../../collaborator.service';
import { filter, take } from 'rxjs/operators';
import { PageModel } from '../../../../models/page.model';
import { CollaboratorOpportunityQuotationPrintComponent } from '../opportunity-quotation-print/opportunity-quotation-print.component';
import { param } from 'jquery';
import { agMakeTextColDef } from '../../../../lib/custom-element/ag-list/column-define/text.define';

@Component({
  selector: 'ngx-opportunity-list',
  templateUrl: './opportunity-list.component.html',
  styleUrls: ['./opportunity-list.component.scss']
})
export class CollaboratorOpportunityListComponent extends AgGridDataManagerListComponent<CollaboratorOpportunityModel, CollaboratorOpportunityFormComponent> implements OnInit {

  componentName: string = 'CollaboratorOpportunityListComponent';
  formPath = '/collaborator/opportunity/form';
  apiPath = '/collaborator/opportunities';
  idKey = ['Code'];

  // Use for load settings menu for context
  feature = {
    Module: { id: 'Purchase', text: 'Thu mua' },
    Feature: { id: 'PurchaseOrder', text: 'Đơn đặt mua hàng' }
  };

  printDialog = CollaboratorOpportunityPrintComponent;
  // formDialog = CollaboratorOpportunityFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';

  static freezeState: ProcessMap = {
    state: 'FREEZE',
    label: 'Lạnh',
    confirmLabel: 'Chuyển sang Lạnh',
    status: 'info',
    outline: true,
    confirmTitle: 'Chuyển sang Lạnh',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Lạnh',
    responseTitle: 'Chuyển sang trạng thái Lạnh',
    responseText: 'Đã chuyển sang trạng thái Lạnh',
  };
  static coolState: ProcessMap = {
    state: 'COOL',
    label: 'Mát',
    confirmLabel: 'Chuyển sang Mát',
    status: 'primary',
    outline: true,
    confirmTitle: 'Chuyển sang Mát',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Mát',
    responseTitle: 'Chuyển sang trạng thái Mát',
    responseText: 'Đã chuyển sang trạng thái Mát',
  };
  static warmState: ProcessMap = {
    state: 'WARM',
    label: 'Ấm',
    confirmLabel: 'Chuyển sang Ấm',
    status: 'warning',
    outline: true,
    confirmTitle: 'Chuyển sang Ấm',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Ấm',
    responseTitle: 'Chuyển sang trạng thái Ấm',
    responseText: 'Đã chuyển sang trạng thái Ấm',
  };
  static hotState: ProcessMap = {
    state: 'HOT',
    label: 'Nóng',
    confirmLabel: 'Chuyển sang Nóng',
    status: 'danger',
    outline: true,
    confirmTitle: 'Chuyển sang Nóng',
    confirmText: 'Bạn có muốn chuyển sang trạng thái Nóng',
    responseTitle: 'Chuyển sang trạng thái Nóng',
    responseText: 'Đã chuyển sang trạng thái Nóng',
    nextState: 'CONVERTED',
  };
  static convertedState: ProcessMap = {
    state: 'CONVERTED',
    label: 'Đã chuyển đổi',
    confirmLabel: 'Chuyển đổi',
    status: 'success',
    outline: true,
    confirmTitle: 'Chuyển đổi',
    confirmText: 'Bạn có muốn chuyển đổi',
    responseTitle: 'Chuyển đổi',
    responseText: 'Đã chuyển đổi',
    nextState: 'FREEZE',
  };

  static unrecordedState: ProcessMap = {
    state: 'UNRECORDED',
    status: 'warning',
    label: 'Common.unrecorded',
    confirmLabel: 'Common.unrecord',
    confirmTitle: 'Common.unrecorded',
    confirmText: 'Common.unrecordedConfirm',
    responseTitle: 'Common.unrecorded',
    responseText: 'Common.unrecordedResponse',
  };
  static notJustApprodedState: ProcessMap = {
    state: 'NOTJUSTAPPROVED',
    label: 'Common.notJustApproved',
    confirmLabel: 'Common.unApprove',
    status: 'info',
    outline: false,
    confirmTitle: 'Common.unApprove',
    confirmText: 'Common.unApproveConfirmText',
    responseTitle: 'Common.unApprove',
    responseText: 'Common.unApproveSuccessText',
  };

  static processingMap = {
    "FREEZE": {
      ...CollaboratorOpportunityListComponent.freezeState,
      nextState: 'COOL',
      nextStates: [
        CollaboratorOpportunityListComponent.coolState,
        CollaboratorOpportunityListComponent.unrecordedState,
      ],
    },
    "COOL": {
      ...CollaboratorOpportunityListComponent.coolState,
      nextState: 'WARM',
      nextStates: [
        CollaboratorOpportunityListComponent.warmState,
        CollaboratorOpportunityListComponent.unrecordedState,
      ],
    },
    "WARM": {
      ...CollaboratorOpportunityListComponent.warmState,
      nextState: 'HOT',
      nextStates: [
        CollaboratorOpportunityListComponent.hotState,
        CollaboratorOpportunityListComponent.unrecordedState,
      ],
    },
    "HOT": {
      ...CollaboratorOpportunityListComponent.hotState,
      nextState: 'CONVERTED',
      nextStates: [
        CollaboratorOpportunityListComponent.convertedState,
        CollaboratorOpportunityListComponent.unrecordedState,
      ],
    },
    "CONVERTED": {
      ...CollaboratorOpportunityListComponent.convertedState,
      nextState: 'FREEZE',
      nextStates: [
        CollaboratorOpportunityListComponent.unrecordedState,
      ],
    },
    "UNRECORDED": {
      ...CollaboratorOpportunityListComponent.unrecordedState,
      nextState: 'FREEZE',
      nextStates: [
        CollaboratorOpportunityListComponent.freezeState,
      ],
    },
    "NOTJUSTAPPROVED": {
      ...CollaboratorOpportunityListComponent.notJustApprodedState,
      nextState: 'FREEZE',
      nextStates: [
        CollaboratorOpportunityListComponent.freezeState,
      ],
    },
    "": {
      ...CollaboratorOpportunityListComponent.notJustApprodedState,
      nextState: 'FREEZE',
      nextStates: [
        CollaboratorOpportunityListComponent.freezeState,
      ],
    },
  };

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<CollaboratorOpportunityListComponent>,
    public collaboratorService: CollaboratorService,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    return super.init().then(async state => {
      await this.cms.waitForLanguageLoaded();
      // Add page choosed
      this.collaboratorService.pageList$.pipe(filter(f => f && f.length > 0), take(1)).toPromise().then(pageList => {
        this.actionButtonList.unshift({
          type: 'select2',
          name: 'pbxdomain',
          status: 'success',
          label: 'Select page',
          icon: 'plus',
          title: this.cms.textTransform(this.cms.translate.instant('Collaborator.Page.title', { action: this.cms.translateText('Common.choose'), definition: '' }), 'head-title'),
          size: 'medium',
          select2: {
            data: pageList,
            option: {
              placeholder: 'Chọn trang...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              keyMap: {
                id: 'id',
                text: 'text',
              },
            }
          },
          asyncValue: this.collaboratorService.currentpage$,
          change: (value: any, option: any) => {
            this.onChangePage(value);
          },
          disabled: () => {
            return false;
          },
          click: () => {
            // this.gotoForm();
            return false;
          },
        });
      });

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Khách hàng',
          field: 'Customer',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'ID App ProBox',
          field: 'Publisher',
          // pinned: 'left',
          width: 250,
          cellRenderer: AgTextCellRenderer,
          valueGetter: (params) => {
            if (params.node?.data?.Publisher?.text) {
              return {
                ...params.node?.data?.Publisher,
                text: `<div>${params.node.data.Publisher.text}</div><div>ProBox.vn/${params.node.data.Publisher.refUserId}</div>`,
              };
            }
            return params?.node?.data?.Publisher;
          },
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Tiêu đề',
          field: 'Title',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeTagsColDef(this.cms, (tag) => {
            this.cms.previewVoucher(tag.type, tag.id);
          }),
          headerName: 'Chứng từ liên quan',
          field: 'RelativeVouchers',
          width: 330,
        },
        {
          headerName: 'Ghi chú 1',
          field: 'Note',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Ghi chú 2',
          field: 'SubNote',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          // ...agMakeTagsColDef(this.cms, (tag) => {
          //   this.cms.previewVoucher(tag.type, tag.id);
          // }),
          cellRenderer: AgTextCellRenderer,
          headerName: 'Sản phẩm',
          field: 'Details',
          width: 330,
          valueGetter: (params) => {
            return /*html*/`<ul><li>` + (params?.data?.Details || []).map(m => this.cms.getObjectText(m.Product)).join('</li><li>') + `</li></ul>`;
          }
        },
        {
          headerName: 'Ngày phát sinh',
          field: 'DateOfOrder',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          headerName: 'Sales',
          field: 'Seller',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Sales Manger',
          field: 'SalesManager',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Chăm sóc CTV',
          field: 'PublisherSupporter',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Người tạo',
          field: 'Creator',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn người tạo...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Người tiếp nhận',
          field: 'ACCEPTBY',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn người tiếp nhận...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
          valueGetter: (params) => {
            if (params && params.data && params.data.StateLogs) {
              const collStateLog = params.data.StateLogs.find(f => this.cms.getObjectId(f) == 'FREEZE');
              if (collStateLog) {
                return collStateLog.ChangerName + ' (' + new Date(collStateLog.Changed).toLocaleString() + ')';
              }
            }
            return null;
          },
        },
        {
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeTextColDef(this.cms),
          headerName: 'Nguồn',
          field: 'Source',
          width: 180,
          valueGetter: params => {
            if (params && params.data) {
              if (params.data.Source && params.data.SourceCode) {
                return (params.data.Source) + '/' + params.data.SourceCode;
              }
            }
            return null;
          }
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Phí cơ bản',
          field: 'BasicCost',
          pinned: 'right',
          width: 100,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Phí nâng cao',
          field: 'AdvanceCost',
          pinned: 'right',
          width: 100,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Phí add-on',
          field: 'AddOnCost',
          pinned: 'right',
          width: 100,
        },
        {
          ...agMakeStateColDef(this.cms, CollaboratorOpportunityListComponent.processingMap, (data) => {
            this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true, [
            {
              name: 'PriceQuotation',
              icon: 'printer-outline',
              title: 'In báo giá',
              outline: false,
              status: 'success',
              action: async (params) => {
                // window.open(this.apiService.buildApiUrl(this.apiPath, { renderPdfQuotation: 'download' }), '_blank');
                this.cms.openDialog(CollaboratorOpportunityQuotationPrintComponent, {
                  // closeOnEsc: false,
                  context: {
                    showLoadinng: true,
                    title: 'Xem trước báo giá',
                    id: [params.data].map(m => this.makeId(m)),
                    sourceOfDialog: 'list',
                    mode: 'print',
                    idKey: ['Code'],
                    // approvedConfirm: true,
                    onChange: (data: CollaboratorOpportunityModel) => {
                      // this.refresh();
                      this.refreshItems([this.makeId(data)]);
                    },
                    onSaveAndClose: (data: CollaboratorOpportunityModel) => {
                      // this.refresh();
                      this.refreshItems([this.makeId(data)]);
                    },
                  },
                });
                return true;
              }
            }
          ]),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }

  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeContact'] = true;
    params['includeCustomer'] = true;
    params['includePublisher'] = true;
    params['includeSeller'] = true;
    params['includeSalesManager'] = true;
    params['includePublisherSupporter'] = true;
    params['includeCreator'] = true;
    params['includeRelativeVouchers'] = true;
    params['includeStateLogs'] = true;
    params['includeDetails'] = true;
    // params['sort_Id'] = 'desc';
    return params;
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: CollaboratorOpportunityModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(CollaboratorOpportunityFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: CollaboratorOpportunityModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

  onChangePage(page: PageModel) {
    if (page !== null) {
      this.collaboratorService.currentpage$.next(this.cms.getObjectId(page));
      this.cms.takeOnce(this.componentName + '_on_domain_changed', 1000).then(() => {
        this.refresh();
      });
    }
  }

  // async preview<T>(data: CollaboratorOpportunityModel[], source?: string, userConfig?: Partial<NbDialogConfig<Partial<T> | string>>) {
  //   window.open(this.apiPath + '/'+this.makeId(data[0])+'?renderPdfQuotation=download', '_blank');
  //   return false;
  // }
}
