import { CollaboratorOpportunityCostStrategyPublisherModel } from '../../../../models/collaborator.model';
import { CurrencyPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbDialogService, NbDialogRef, NbThemeService } from '@nebular/theme';
import { DataManagerFormComponent } from '../../../../lib/data-manager/data-manager-form.component';
import { ProductModel, ProductUnitModel } from '../../../../models/product.model';
import { ApiService } from '../../../../services/api.service';
import { CommonService } from '../../../../services/common.service';
import { CollaboratorService } from '../../collaborator.service';
import { ChangeDetectorRef } from '@angular/core';
import { ColDef, ColumnApi, GridApi, IRowNode } from '@ag-grid-community/core';
import { AgTextCellRenderer } from '../../../../lib/custom-element/ag-list/cell/text.component';
import { CollaboratorAddonStrategyPublisherModel, CollaboratorOpportunityCostStrategyModel, CollaboratorBasicStrategyProductModel } from '../../../../models/collaborator.model';
import { agMakeCommandColDef } from '../../../../lib/custom-element/ag-list/column-define/command.define';
import { agMakeImageColDef } from '../../../../lib/custom-element/ag-list/column-define/image.define';
import { agMakeSelectionColDef } from '../../../../lib/custom-element/ag-list/column-define/selection.define';
import { AgDynamicListComponent } from '../../../general/ag-dymanic-list/ag-dymanic-list.component';
import { CollaboratorBasicStrategyProductFormComponent } from '../../basic-strategy/product-form/collaborator-basic-strategy-product-form.component';
import { CollaboratorPublisherListComponent } from '../../publisher/collaborator-publisher-list/collaborator-publisher-list.component';
import { CollaboratorProductListComponent } from '../../product/collaborator-product-list/collaborator-product-list.component';
import { RootServices } from '../../../../services/root.services';
import { AgNumberCellInput } from '../../../../lib/custom-element/ag-list/cell/input/number.component';
import { AgCurrencyCellInput } from '../../../../lib/custom-element/ag-list/cell/input/curency.component';
import { AgSelect2CellInput } from '../../../../lib/custom-element/ag-list/cell/input/select2.component';
@Component({
  selector: 'ngx-collaborator-opportunity-cost-strategy-form',
  templateUrl: './opportunity-cost-strategy-form.component.html',
  styleUrls: ['./opportunity-cost-strategy-form.component.scss'],
  providers: [
    CurrencyPipe
  ]
})
export class CollaboratorOpportunityCostStrategyFormComponent extends DataManagerFormComponent<CollaboratorOpportunityCostStrategyModel> implements OnInit {


  componentName: string = 'CollaboratorOpportunityCostStrategyFormComponent';
  idKey = ['Code'];
  apiPath = '/collaborator/opportunity-cost-strategies';
  baseFormUrl = '/collaborator/opportunity-cost-strategy/form';
  themeName = this.themeService.currentTheme == 'default' ? '' : this.themeService.currentTheme;
  unitList: ProductUnitModel[] = [];

  constructor(
    public rsv: RootServices,
    public activeRoute: ActivatedRoute,
    public router: Router,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public toastrService: NbToastrService,
    public dialogService: NbDialogService,
    public cms: CommonService,
    public ref?: NbDialogRef<CollaboratorOpportunityCostStrategyFormComponent>,
    public collaboratorService?: CollaboratorService,
    public themeService?: NbThemeService,
    public onDetectChangeRef?: ChangeDetectorRef
  ) {
    super(rsv, activeRoute, router, formBuilder, apiService, toastrService, dialogService, cms);


    const $this = this;
    /** AG-Grid */
    this.columnDefs = [
      {
        ...agMakeSelectionColDef(this.cms),
        headerName: 'STT',
        field: 'Id',
        valueGetter: 'node.data.Publisher',
      },
      {
        ...agMakeImageColDef(this.cms),
        headerName: 'Avatar',
        field: 'Avatar',
        width: 100,
      },
      {
        headerName: 'Tên CTV',
        field: 'PublisherName',
        width: 320,
        filter: 'agTextColumnFilter',
        cellRenderer: AgTextCellRenderer,
        // pinned: 'left',
      },
      {
        headerName: 'Trạng thái phí cơ bản',
        field: 'BasicState',
        width: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: AgSelect2CellInput,
        cellClass: 'ag-cell-full-width',
        cellRendererParams: {
          list: [
            { id: 'FREEZE', text: 'Lạnh' },
            { id: 'COOL', text: 'Mát' },
            { id: 'WARM', text: 'Ấm' },
            { id: 'HOT', text: 'Nóng' },
            { id: 'TRANSFERED', text: 'Đã chuyển đổi' },
          ]
        },
        pinned: 'right', 
      },
      {
        headerName: 'Phí cơ bản',
        field: 'BasicCost',
        width: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: AgCurrencyCellInput,
        cellRendererParams: {
          takeUntilDelay: 0,
        },
        pinned: 'right',
      },
      {
        headerName: 'Trạng thái phí nâng cao',
        field: 'AdvanceState',
        width: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: AgSelect2CellInput,
        cellClass: 'ag-cell-full-width',
        cellRendererParams: {
          list: [
            { id: 'FREEZE', text: 'Lạnh' },
            { id: 'COOL', text: 'Mát' },
            { id: 'WARM', text: 'Ấm' },
            { id: 'HOT', text: 'Nóng' },
            { id: 'TRANSFERED', text: 'Đã chuyển đổi' },
          ]
        },
        pinned: 'right', 
      },
      {
        headerName: 'Phí nâng cao',
        field: 'AdvanceCost',
        width: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: AgCurrencyCellInput,
        cellRendererParams: {
          takeUntilDelay: 0,
        },
        pinned: 'right',
      },
      {
        headerName: 'Trạng thái phí Add-on',
        field: 'AddOnState',
        width: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: AgSelect2CellInput,
        cellClass: 'ag-cell-full-width',
        cellRendererParams: {
          list: [
            { id: 'FREEZE', text: 'Lạnh' },
            { id: 'COOL', text: 'Mát' },
            { id: 'WARM', text: 'Ấm' },
            { id: 'HOT', text: 'Nóng' },
            { id: 'TRANSFERED', text: 'Đã chuyển đổi' },
          ]
        },
        pinned: 'right', 
      },
      {
        headerName: 'Phí add-on',
        field: 'AddOnCost',
        width: 200,
        filter: 'agTextColumnFilter',
        cellRenderer: AgCurrencyCellInput,
        cellRendererParams: {
          takeUntilDelay: 0,
        },
        pinned: 'right',
      },
      {
        ...agMakeCommandColDef(null, this.cms, false, (params) => {
          this.gridApi.applyTransaction({ remove: [params] });
        }, false, [
        ]),
        // width: 123,
        headerName: 'Lệnh',
      },
    ];


    /** End AG-Grid */
  }

  /** AG-Grid */
  public gridApi: GridApi;
  public gridColumnApi: ColumnApi;
  public columnDefs: ColDef[];
  public gridParams;

  onGridReady(params) {
    this.gridParams = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadList();
  }

  loadList(callback?: (list: CollaboratorBasicStrategyProductModel[]) => void) {
    if (this.gridApi) {
      let publishers: CollaboratorOpportunityCostStrategyPublisherModel[] = (this.array.controls[0].get('Publishers').value || []).map((publisher: CollaboratorOpportunityCostStrategyPublisherModel) => {
        if (!publisher.Products) {
          publisher.Products = [];
        }
        publisher.Products.map(product => {
          product['id'] = this.cms.getObjectId(product.Product) + '-' + this.cms.getObjectId(product.Unit);
          product['text'] = product.ProductName + '/' + this.cms.getObjectText(product.Unit) + '/' + product.Level1CommissionRatio;
        });
        return publisher;
      });
      this.gridApi.setRowData(publishers);
    }
  }
  select2OptionForPage = {
    placeholder: 'Chọn trang...',
    allowClear: false,
    width: '100%',
    dropdownAutoWidth: true,
    minimumInputLength: 0,
    keyMap: {
      id: 'id',
      text: 'text',
    },
  };

  async loadCache() {
    // iniit category
    // this.categoryList = (await this.apiService.getPromise<ProductCategoryModel[]>('/admin-product/categories', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.groupList = (await this.apiService.getPromise<ProductGroupModel[]>('/admin-product/groups', { limit: 'nolimit' })).map(cate => ({ id: cate.Code, text: cate.Name })) as any;
    // this.productList = (await this.apiService.getPromise<ProductModel[]>('/admin-product/products', { limit: 100, includeIdText: true }));
  }

  getRequestId(callback: (id?: string[]) => void) {
    if (this.mode === 'page') {
      super.getRequestId(callback);
    } else {
      callback(this.inputId);
    }
  }

  ngOnInit() {
    this.restrict();
    super.ngOnInit();
  }

  async init() {
    await this.loadCache();
    return super.init().then(rs => {
      return rs;
    });
  }

  /** Execute api get */
  executeGet(params: any, success: (resources: ProductModel[]) => void, error?: (e: HttpErrorResponse) => void) {
    params['includePublishers'] = true;
    super.executeGet(params, success, error);
  }

  async formLoad(formData: ProductModel[], formItemLoadCallback?: (index: number, newForm: FormGroup, formData: ProductModel) => void) {
    return super.formLoad(formData, async (index, newForm, itemFormData) => {

      if (this.gridApi) {
        this.loadList();
      }

      // Direct callback
      if (formItemLoadCallback) {
        formItemLoadCallback(index, newForm, itemFormData);
      }
    });
  }

  makeNewFormGroup(data?: ProductModel): FormGroup {
    const currentDate = new Date();
    const newForm = this.formBuilder.group({
      Code: { value: '', disabled: true },
      Title: ['', Validators.required],
      Page: [this.collaboratorService.currentpage$.value, Validators.required],
      DateRange: [[Date.today(), Date.today().next().month()], Validators.required],
      Publishers: [[]],
    });
    if (data) {
      data.DateRange = [data.DateOfStart, data.DateOfEnd];
      newForm.patchValue(data);
    }
    return newForm;
  }
  onAddFormGroup(index: number, newForm: FormGroup, formData?: ProductModel): void {
    super.onAddFormGroup(index, newForm, formData);
  }
  onRemoveFormGroup(index: number): void {

  }

  goback(): false {
    super.goback();
    if (this.mode === 'page') {
      this.router.navigate(['/admin-product/product/list']);
    } else {
      this.ref.close();
    }
    return false;
  }

  onUpdatePastFormData(aPastFormData: { formData: any; meta: any; }): void { }
  onUndoPastFormData(aPastFormData: { formData: any; meta: any; }): void { }

  /** Execute api put */
  executePut(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePut(params, data, success, error);
  }

  /** Execute api post */
  executePost(params: any, data: ProductModel[], success: (data: ProductModel[]) => void, error: (e: any) => void) {
    return super.executePost(params, data, success, error);
  }

  getRawFormData() {
    const data = super.getRawFormData();
    for (const item of data.array) {
      // Extract date range
      if (item.DateRange) {
        item.DateOfStart = item.DateRange[0];
        item.DateOfEnd = item.DateRange[1];
      }

      // Get details data from ag-grid
      item.Publishers = [];
      this.gridApi.forEachNode((rowNode, index) => {
        console.log(rowNode, index);
        const rawDetail = {};
        for (const prop in rowNode.data) {
          rawDetail[prop] = this.cms.getObjectId(rowNode.data[prop]);
        }
        item.Publishers.push(rawDetail);
      });
    }
    return data;
  }

  async save(): Promise<ProductModel[]> {
    return super.save();
  }

  onGridInit(component: AgDynamicListComponent<any>) {
    const $this = this;
    let actionButtonList = component.actionButtonList;
    // actionButtonList = actionButtonList.filter(f => f.name != 'choose');
    actionButtonList = [];
    actionButtonList.unshift({
      type: 'button',
      name: 'delete',
      title: 'Gở CTV Bán Hàng',
      status: 'danger',
      label: 'Gở',
      iconPack: 'eva',
      icon: 'minus-square-outline',
      size: 'medium',
      click: (event) => {
        const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
        $this.gridApi.applyTransaction({ remove: selectedNodes.map(m => m.data) });

        return true;
      }
    });
    actionButtonList.unshift({
      type: 'button',
      name: 'add',
      title: 'Thêm CTV Bán Hàng',
      status: 'success',
      label: 'Thêm CTV Bán Hàng',
      iconPack: 'eva',
      icon: 'plus-square-outline',
      size: 'medium',
      click: (event) => {
        // const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();

        this.cms.openDialog(CollaboratorPublisherListComponent, {
          context: {
            gridHeight: '90vh',
            onDialogChoose(chooseItems) {
              console.log(chooseItems);
              const newRowNodeTrans = $this.gridApi.applyTransaction({
                add: chooseItems.map(m => ({
                  id: m.Contact,
                  text: m.Name,
                  Publisher: m.Contact,
                  PublisherName: m.Name,
                  Avatar: m.Avatar,
                }))
              });
              console.log('New Row Node Trans: ', newRowNodeTrans);
            },
          }
        });

        return true;
      }
    });

    // actionButtonList.unshift({
    //   type: 'button',
    //   name: 'settings',
    //   title: 'Cấu hình',
    //   status: 'primary',
    //   label: 'Cài đặt',
    //   iconPack: 'eva',
    //   icon: 'settings-2-outline',
    //   size: 'medium',
    //   click: (event) => {
    //     const selectedNodes: IRowNode[] = $this.gridApi.getSelectedNodes();

    //     // Setting for product
    //     if (selectedNodes && selectedNodes.length > 0) {
    //       this.cms.openDialog(CollaboratorBasicStrategyProductFormComponent, {
    //         context: {
    //           data: selectedNodes.map(m => m.data),
    //           onDialogSave(newData) {
    //             console.log(newData);
    //             for (const itemData of newData) {
    //               let currentNode: IRowNode = $this.gridApi.getRowNode($this.cms.getObjectId(itemData.Product) + '-' + $this.cms.getObjectId(itemData.Unit));
    //               currentNode.setData(itemData);
    //             }
    //           },
    //         }
    //       });
    //     }

    //     return true;
    //   }
    // });

    component.actionButtonList = actionButtonList;
  }

  productExtendData: any = {};
  selectedPublisherNode: IRowNode<CollaboratorOpportunityCostStrategyPublisherModel> = null;
  onPublishersSelected(nodes: IRowNode<CollaboratorOpportunityCostStrategyPublisherModel>[]) {
    console.log('On Publishers selected: ', nodes);
    // if (nodes.length == 1) {
    //   // Load relative products
    //   this.selectedPublisherNode = nodes[0];
    // } else {
    //   // Clear relative products
    //   this.selectedPublisherNode = null;
    // }
  }
}
