import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NbDialogService, NbToastrService, NbThemeService, NbDialogRef } from "@nebular/theme";
import { ColDef, IGetRowsParams } from "@ag-grid-community/core";
import { filter, take } from "rxjs/operators";
import { AppModule } from "../../../../../app.module";
import { AgDateCellRenderer } from "../../../../../lib/custom-element/ag-list/cell/date.component";
import { AgTextCellRenderer } from "../../../../../lib/custom-element/ag-list/cell/text.component";
import { agMakeCommandColDef } from "../../../../../lib/custom-element/ag-list/column-define/command.define";
import { agMakeCurrencyColDef } from "../../../../../lib/custom-element/ag-list/column-define/currency.define";
import { agMakeSelectionColDef } from "../../../../../lib/custom-element/ag-list/column-define/selection.define";
import { agMakeStateColDef } from "../../../../../lib/custom-element/ag-list/column-define/state.define";
import { agMakeTagsColDef } from "../../../../../lib/custom-element/ag-list/column-define/tags.define";
import { AgSelect2Filter } from "../../../../../lib/custom-element/ag-list/filter/select2.component.filter";
import { AgGridDataManagerListComponent } from "../../../../../lib/data-manager/ag-grid-data-manger-list.component";
import { CollaboratorReportSalaryModel } from "../../../../../models/collaborator.model";
import { PageModel } from "../../../../../models/page.model";
import { ApiService } from "../../../../../services/api.service";
import { CommonService } from "../../../../../services/common.service";
import { RootServices } from "../../../../../services/root.services";
import { CollaboratorService } from "../../../collaborator.service";
import { CollaboratorReportSalaryFormComponent } from "../report-salary-form/report-salary-form.component";
import { CollaboratorReportSalaryPrintComponent } from "../report-salary-print/report-salary-print.component";


@Component({
  selector: 'ngx-report-salary-list',
  templateUrl: './report-salary-list.component.html',
  styleUrls: ['./report-salary-list.component.scss']
})
export class CollaboratorReportSalaryListComponent extends AgGridDataManagerListComponent<CollaboratorReportSalaryModel, CollaboratorReportSalaryFormComponent> implements OnInit {

  componentName: string = 'CollaboratorReportSalaryListComponent';
  formPath = '/collaborator/report/salary/form';
  apiPath = '/collaborator/report-salaries';
  idKey = ['Code'];

  // Use for load settings menu for context
  feature = {
    Module: { id: 'Purchase', text: 'Thu mua' },
    Feature: { id: 'PurchaseOrder', text: 'Đơn đặt mua hàng' }
  };

  printDialog = CollaboratorReportSalaryPrintComponent;
  formDialog = CollaboratorReportSalaryFormComponent;

  // AG-Grid config
  public rowHeight: number = 50;
  // @Input() suppressRowClickSelection = false;

  @Input() gridHeight = '100%';

  queue = [];


  // static processingMap = AppModule.processMaps.common;

  constructor(
    public rsv: RootServices,
    public apiService: ApiService,
    public router: Router,
    public cms: CommonService,
    public dialogService: NbDialogService,
    public toastService: NbToastrService,
    public themeService: NbThemeService,
    public ref: NbDialogRef<CollaboratorReportSalaryListComponent>,
    public collaboratorService: CollaboratorService,
    public datePipe: DatePipe,
  ) {
    super(rsv, apiService, router, cms, dialogService, toastService, themeService, ref);

    this.defaultColDef = {
      ...this.defaultColDef,
      cellClass: 'ag-cell-items-center',
    }

    this.pagination = false;
    // this.maxBlocksInCache = 5;
    this.paginationPageSize = 100;
    this.cacheBlockSize = 100;
  }

  async init() {
    return super.init().then(async state => {
      await this.cms.waitForLanguageLoaded();

      // this.actionButtonList.unshift({
      //   type: 'button',
      //   name: 'queueAction',
      //   status: 'info',
      //   label: 'Hàng đợi',
      //   title: 'Xem hàng đợi',
      //   size: 'medium',
      //   icon: 'layers-outline',
      //   disabled: () => {
      //     return this.queue.length == 0;
      //   },
      //   click: () => {
      //     // this.queueParams = {};
      //     // this.queueParams['eq_' + this.idKey[0]] = '[' + this.queue.map(m => this.makeId(m)).join(',') + ']';
      //     // this.reset();
      //   }
      // });

      // Add page choosed
      this.collaboratorService.pageList$.pipe(filter(f => f && f.length > 0), take(1)).toPromise().then(pageList => {
        this.actionButtonList.unshift({
          type: 'select2',
          name: 'page',
          status: 'success',
          label: 'Select page',
          icon: 'plus',
          title: this.cms.textTransform(this.cms.translate.instant('Collaborator.Page.title', { action: this.cms.translateText('Common.choose'), definition: '' }), 'head-title'),
          size: 'medium',
          select2: {
            data: pageList,
            option: {
              placeholder: 'Chọn trang...',
              allowClear: true,
              width: '100%',
              dropdownAutoWidth: true,
              minimumInputLength: 0,
              keyMap: {
                id: 'id',
                text: 'text',
              },
            }
          },
          asyncValue: this.collaboratorService.currentpage$,
          change: (value: any, option: any) => {
            this.onChangePage(value);
          },
          disabled: () => {
            return false;
          },
          click: () => {
            // this.gotoForm();
            return false;
          },
        });
      });

      this.columnDefs = this.configSetting([
        {
          ...agMakeSelectionColDef(this.cms),
          headerName: 'ID',
          field: 'Id',
          width: 100,
          valueGetter: 'node.data.Id',
          // sortingOrder: ['desc', 'asc'],
          initialSort: 'desc',
        },
        {
          headerName: 'Mã',
          field: 'Code',
          width: 140,
          filter: 'agTextColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Nhân viên/CTV',
          field: 'Object',
          pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Tiêu đề',
          field: 'Title',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          ...agMakeTagsColDef(this.cms, (tag) => {
            this.cms.previewVoucher(tag.type, tag.id);
          }),
          headerName: 'Chứng từ liên quan',
          field: 'RelativeVouchers',
          width: 330,
        },
        {
          headerName: 'Ghi chú 1',
          field: 'Note',
          width: 300,
          filter: 'agTextColumnFilter',
          autoHeight: true,
        },
        {
          headerName: 'Ngày chứng từ',
          field: 'DateOfVoucher',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          headerName: 'ID App ProBox',
          field: 'PublisherRefId',
          // pinned: 'left',
          width: 250,
          cellRenderer: AgTextCellRenderer,
          valueGetter: (params) => {
            if (params.node?.data?.Publisher?.text) {
              return {
                ...params.node?.data?.Publisher,
                text: `<div>${params.node.data.Publisher.text}</div><div>ProBox.vn/${params.node.data.Publisher.refUserId}</div>`,
              };
            }
            return params?.node?.data?.Publisher;
          },
          // filter: AgSelect2Filter,
          // filterParams: {
          //   select2Option: {
          //     ...this.cms.makeSelect2AjaxOption('/contact/contacts', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
          //       placeholder: 'Chọn liên hệ...', limit: 10, prepareReaultItem: (item) => {
          //         item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'] + '' + (item['Groups'] ? (' (' + item['Groups'].map(g => g.text).join(', ') + ')') : '');
          //         return item;
          //       }
          //     }),
          //     multiple: true,
          //     logic: 'OR',
          //     allowClear: true,
          //   }
          // },
        },
        {
          headerName: 'Người tạo',
          field: 'Creator',
          // pinned: 'left',
          width: 200,
          cellRenderer: AgTextCellRenderer,
          filter: AgSelect2Filter,
          filterParams: {
            select2Option: {
              ...this.cms.makeSelect2AjaxOption('/user/users', { includeIdText: true, includeGroups: true, sort_SearchRank: 'desc' }, {
                placeholder: 'Chọn người tạo...', limit: 10, prepareReaultItem: (item) => {
                  item['text'] = item['Code'] + ' - ' + (item['Title'] ? (item['Title'] + '. ') : '') + (item['ShortName'] ? (item['ShortName'] + '/') : '') + item['Name'];
                  return item;
                }
              }),
              multiple: true,
              logic: 'OR',
              allowClear: true,
            }
          },
        },
        {
          headerName: 'Ngày tạo',
          field: 'Created',
          width: 180,
          filter: 'agDateColumnFilter',
          filterParams: {
            inRangeFloatingFilterDateFormat: 'DD/MM/YY',
          },
          cellRenderer: AgDateCellRenderer,
        },
        {
          ...agMakeCurrencyColDef(this.cms),
          headerName: 'Số tiền',
          field: 'Amount',
          pinned: 'right',
          width: 150,
        },
        {
          ...agMakeStateColDef(this.cms, AppModule.processMaps.common, (data) => {
            this.preview([data]);
          }),
          headerName: 'Trạng thái',
          field: 'State',
          width: 155,
        },
        {
          ...agMakeCommandColDef(this, this.cms, true, true, true, [
            // {
            //   name: 'pushToQueue',
            //   // title: '',
            //   icon: 'layers-outline',
            //   status: 'info',
            //   outline: false,
            //   appendTo: 'head',
            //   action: async (params, btnConf) => {
            //     this.queue.push(params.node.data);
            //     return false;
            //   }
            // }
          ]),
          headerName: 'Lệnh',
        },
      ] as ColDef[]);

      return state;
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  // @Input() getRowHeight = (params: RowHeightParams<CommercePosOrderModel>) => {
  //   return 123;
  // }
  queueParams = {};
  prepareApiParams(params: any, getRowParams: IGetRowsParams) {
    params['includeContact'] = true;
    params['includeObject'] = true;
    params['includePublisher'] = true;
    params['includeSeller'] = true;
    params['includeSalesManager'] = true;
    params['includePublisherSupporter'] = true;
    params['includeCreator'] = true;
    params['includeRelativeVouchers'] = true;
    params['includeStateLogs'] = true;
    params['includeDetails'] = true;
    // params['sort_Id'] = 'desc';
    return {
      ...params,
      ...this.queueParams
    };
  }

  /** Implement required */
  openFormDialplog(ids?: string[], onDialogSave?: (newData: CollaboratorReportSalaryModel[]) => void, onDialogClose?: () => void) {
    this.cms.openDialog(CollaboratorReportSalaryFormComponent, {
      context: {
        inputMode: 'dialog',
        inputId: ids,
        onDialogSave: (newData: CollaboratorReportSalaryModel[]) => {
          if (onDialogSave) onDialogSave(newData);
        },
        onDialogClose: () => {
          if (onDialogClose) onDialogClose();
        },
      },
    });
    return false;
  }

  onGridReady(params) {
    super.onGridReady(params);
  }

  onChangePage(page: PageModel) {
    if (page !== null) {
      this.collaboratorService.currentpage$.next(this.cms.getObjectId(page));
      this.cms.takeOnce(this.componentName + '_on_domain_changed', 1000).then(() => {
        this.refresh();
      });
    }
  }

  // async preview<T>(data: CollaboratorReportSalaryModel[], source?: string, userConfig?: Partial<NbDialogConfig<Partial<T> | string>>) {
  //   window.open(this.apiPath + '/'+this.makeId(data[0])+'?renderPdfQuotation=download', '_blank');
  //   return false;
  // }
}
