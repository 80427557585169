<form [formGroup]="form" (keydown.shift.enter)="onControlEnter($event)" (keydown.enter)="onControlEnter($event)" class="dialog-wrap popup">
    <div formArrayName="array">
        <div *ngFor="let formItem of array.controls; let i = index">
            <nb-card class="small-header popup">
                <nb-card-header>
                    <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('Sales.AuthorizedSaleVoucher.title' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
                </nb-card-header>
                <nb-card-body>
                    <div [formGroup]="formItem">
                        <label class="label label-with-border-below">{{'Common.contactInfo' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-12">
                                <nb-tabset>
                                    <nb-tab tabTitle="{{'Định danh cung cấp' | translate | headtitlecase}} (*)">
                                        <!-- <label class="label label-with-border-below">{{'Common.mainInfo' | translate | headtitlecase}}</label> -->
                                        <div class="row">
                                            <!-- <input type="hidden" formControlName="Code"> -->
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="Supplier" label="Common.supplier" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [customIcons]="objectControlIcons">
                                                    <nb-checkbox class="form-group-icon-control" formControlName="IsAuthorizedObject" nbTooltip="Sử dụng làm thông tin ủy quyền (mượn danh bán hàng)"></nb-checkbox>
                                                    <ngx-select2 class="form-group-input-control" formControlName="Supplier" [select2Option]="select2OptionForDelegateSupplier" (selectChange)="onSupplierChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="SupplierName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SupplierName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="SupplierPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SupplierPhone" placeholder="{{formItem.get('SupplierPhone').placeholder || ('Common.phone' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <ngx-form-group [formGroup]="formItem" name="Thread" label="Luồng (theo dự án/công trình)" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                            <input type="text" nbInput fullWidth formControlName="Thread" placeholder="{{'Thread' | translate | headtitlecase}}">
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <ngx-form-group [formGroup]="formItem" name="Code" label="ID" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                            <input type="text" nbInput fullWidth formControlName="Code" placeholder="{{'ID' | translate | headtitlecase}}">
                                                        </ngx-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-9">
                                                <ngx-form-group [formGroup]="formItem" name="SupplierAddress" label="Common.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SupplierAddress" placeholder="{{formItem.get('SupplierAddress').placeholder || ('Common.address' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="SupplierEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="SupplierEmail" placeholder="{{formItem.get('SupplierEmail').placeholder || ('Common.email' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </nb-tab>
                                    <nb-tab tabTitle="{{'Common.customer' | translate | headtitlecase}}">
                                        <!-- <label class="label label-with-border-below">{{'Common.contact' | translate | headtitlecase}}</label> -->
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="Customer" label="Common.contact" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [customIcons]="contactControlIcons">
                                                    <ngx-select2 formControlName="Customer" [select2Option]="select2OptionForContact" (selectChange)="onCustomerChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-6">
                                                <ngx-form-group [formGroup]="formItem" name="CustomerName" label="Customer.name" [array]="array" [index]="i" [allowCopy]="false" [required]="true">
                                                    <input type="text" nbInput fullWidth formControlName="CustomerName" placeholder="{{'Customer.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="CustomerPhone" label="Customer.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="CustomerPhone" placeholder="{{formItem.get('CustomerPhone').placeholder || ('Customer.phone' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-9">
                                                <ngx-form-group [formGroup]="formItem" name="CustomerAddress" label="Customer.address" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="CustomerAddress" placeholder="{{formItem.get('CustomerAddress').placeholder || ('Customer.address' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="CustomerEmail" label="Customer.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="CustomerEmail" placeholder="{{formItem.get('CustomerEmail').placeholder || ('Customer.email' | translate | headtitlecase)}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </nb-tab>
                                    <nb-tab tabTitle="{{'Giao nhận' | translate | headtitlecase}}">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DirectReceiver" label="Người trực tiếp nhận hàng" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [customIcons]="contactControlIcons">
                                                    <ngx-select2 formControlName="DirectReceiver" [select2Option]="select2OptionForContact" (selectChange)="onDirectReceiverChange(formItem, $event, i)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DirectReceiverName" label="Common.name" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DirectReceiverName" placeholder="{{'Common.name' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DirectReceiverPhone" label="Common.phone" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DirectReceiverPhone" placeholder="{{'Common.phone' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DirectReceiverEmail" label="Common.email" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DirectReceiverEmail" placeholder="{{'Common.email' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryProvince" label="Địa chỉ nhận hàng, mục tiêu phải đến: Tỉnh/TP" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="DeliveryProvince" [select2Option]="makeSelect2Option(select2OptionForProvince, formItem)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryDistrict" label="Common.district" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="DeliveryDistrict" [select2Option]="makeSelect2Option(select2OptionForDistrict, formItem)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryWard" label="Common.ward" [array]="array" [index]="i" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                                                    <ngx-select2 formControlName="DeliveryWard" [select2Option]="makeSelect2Option(select2OptionForWard, formItem)"></ngx-select2>
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-3">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryAddress" label="Địa chỉ nhận hàng" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DeliveryAddress" placeholder="{{'Số nhà, tên đường...' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                            <div class="col-sm-12">
                                                <ngx-form-group [formGroup]="formItem" name="DeliveryMapLink" label="Link bản đồ" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                                    <input type="text" nbInput fullWidth formControlName="DeliveryMapLink" placeholder="{{'Link bản đồ...' | translate | headtitlecase}}">
                                                </ngx-form-group>
                                            </div>
                                        </div>

                                        <!--Trnsportation-->
                                        <hr>
                                        <label class="label">{{'Hành trình vận chuyển' | translate | headtitlecase}}</label>
                                        <div class="row fit-row form-detail-header">
                                            <div class="fit-fist-col label column">Stt</div>
                                            <!-- <div class="row"> -->
                                            <!-- <div class="col-sm-12"> -->
                                            <div class="row fit-content-column">
                                                <div class="col-sm-2 label column">{{'Nhân viên/ĐV vận chuyển' | translate | headtitlecase}}</div>
                                                <div class="col-sm-2 label column">{{'Common.phone' | translate | headtitlecase}}</div>
                                                <!-- <div class="col-sm-2 label column">{{'Điểm đi' | translate | headtitlecase}}</div>
                                                 -->
                                                <div class="col-sm-2 label column">{{'Common.address' | translate | headtitlecase}}</div>
                                                <div class="col-sm-2 label column">{{'Link bản đồ' | translate | headtitlecase}}</div>
                                                <div class="col-sm-2 label column">{{'Nghiệp vụ kế toán' | translate | headtitlecase}}</div>
                                                <div class="col-sm-2 label column align-right">{{'Phí vận chuyển' | translate | headtitlecase}}</div>
                                            </div>
                                            <!-- </div> -->
                                            <!-- </div> -->
                                            <div class="fit-last-col column">
                                                <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addTransportPointFormGroup(formItem)" size="medium" hero>
                                                    <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div formArrayName="TransportPoints" class="form-details">
                                            <div class="form-detail-item" *ngFor="let transportPoint of formItem.TransportPoints.controls; let ip=index" [formGroup]="transportPoint">
                                                <div class="row fit-row">
                                                    <div class="fit-fist-col label">
                                                        <div class="form-group">
                                                            <!-- <label class="label">Stt</label> -->
                                                            <input class="fit-sequence" nbInput fullWidth disabled="true" value="{{ ip + 1 }}">
                                                            <!-- <input type="hidden" formControlName="Id"> -->
                                                        </div>
                                                    </div>
                                                    <div class="row fit-content-column">
                                                        <div class="col-sm-12">
                                                            <div class="row">
                                                                <div class="col-sm-2">
                                                                    <ngx-form-group [formGroup]="transportPoint" name="ShippingUnit" label="Nhân viên/ĐV vận chuyển" [array]="formItem.TransportPoints" [index]="ip" [allowCopy]="true" [required]="false" [hideLabel]="true">
                                                                        <ngx-select2 formControlName="ShippingUnit" [select2Option]="select2OptionForShippingUnit" (selectChange)="onShippingUnitChange(transportPoint, $event, ip)"></ngx-select2>
                                                                    </ngx-form-group>
                                                                </div>
                                                                <div class="col-sm-2">
                                                                    <ngx-form-group [formGroup]="transportPoint" name="ShippingUnitPhone" label="Common.phone" [array]="formItem.TransportPoints" [index]="ip" [allowCopy]="true" [required]="true" [hideLabel]="true" [touchedValidate]="false">
                                                                        <input type="text" nbInput fullWidth formControlName="ShippingUnitPhone" placeholder="{{'Common.phone' | translate | headtitlecase}}">
                                                                    </ngx-form-group>
                                                                </div>
                                                                <div class="col-sm-2">
                                                                    <ngx-form-group [formGroup]="transportPoint" name="ShippingUnitAddress" label="Common.address" [array]="formItem.TransportPoints" [index]="ip" [allowCopy]="true" [required]="true" [hideLabel]="true" [touchedValidate]="false">
                                                                        <input type="text" nbInput fullWidth formControlName="ShippingUnitAddress" placeholder="{{'Common.address' | translate | headtitlecase}}">
                                                                    </ngx-form-group>
                                                                </div>
                                                                <div class="col-sm-2">
                                                                    <ngx-form-group [formGroup]="transportPoint" name="ShippingUnitMapLink" label="Link bản đồ" [array]="formItem.TransportPoints" [index]="ip" [allowCopy]="true" [required]="true" [hideLabel]="true" [touchedValidate]="false">
                                                                        <input type="text" nbInput fullWidth formControlName="ShippingUnitMapLink" placeholder="{{'Link bản đồ' | translate | headtitlecase}}">
                                                                    </ngx-form-group>
                                                                </div>
                                                                <div class="col-sm-2">
                                                                    <ngx-form-group [formGroup]="transportPoint" name="Business" label="Common.accountingBusiness" [array]="formItem.TransportPoints" [index]="ip" [allowCopy]="true" [required]="true" [hideLabel]="true" [touchedValidate]="false">
                                                                        <ngx-select2 [data]="accountingBusinessListForTransport" formControlName="Business" [select2Option]="select2OptionForAccountingBusinessForTransport"></ngx-select2>
                                                                    </ngx-form-group>
                                                                </div>
                                                                <div class="col-sm-2">
                                                                    <ngx-form-group [formGroup]="transportPoint" name="TransportCost" label="Phí vận chuyển" [array]="formItem.TransportPoints" [index]="ip" [allowCopy]="true" [required]="true" [hideLabel]="true" [touchedValidate]="false">
                                                                        <input type="text" nbInput fullWidth formControlName="TransportCost" placeholder="Phí vận chuyển" class="align-right" currencyMask [options]="toMoneyCurencyFormat" (focus)="currencyMaskFocus($event, toMoneyCurencyFormat)" (keydown)="currencyMastKeydown($event, toMoneyCurencyFormat)" (paste)="onPasteNumber($event, toMoneyCurencyFormat)">
                                                                    </ngx-form-group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="fit-last-col">
                                                        <div class="form-group" style="text-align: right;">
                                                            <!-- <label class="label">CM</label> -->
                                                            <button class="fit-control-button" nbButton status="danger" hero size="medium" (click)="removeTransportPointGroup(formItem, ip)">
                                                                <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-detail-item">
                                                <div class="row fit-row form-detail-footer">
                                                    <div class="fit-fist-col label">

                                                    </div>
                                                    <div class="row fit-content-column">
                                                        <div class="col-sm-12">
                                                            <div class="row">
                                                                <div class="col-sm-4">

                                                                </div>
                                                                <div class="col-sm-6" style="text-align: right; font-weight: bold;">Tổng cộng:</div>
                                                                <div class="col-sm-2" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">
                                                                    {{formItem._totalTransportCost | currency:'VND'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="fit-last-col">
                                                        <div class="form-group" style="text-align: right;">
                                                            <!-- <label class="label">CM</label> -->
                                                            <button class="fit-control-button" nbButton [outline]="true" status="success" (click)="addTransportPointFormGroup(formItem)" size="medium" hero>
                                                                <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--End Trnsportation-->
                                        </nb-tab>
                                </nb-tabset>
                            </div>
                        </div>

                        <label class="label label-with-border-below">{{'Thông tin chung' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">

                            <div class="col-sm-9">
                                <ngx-form-group [formGroup]="formItem" name="Title" label="Common.title" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                    <input type="text" nbInput fullWidth formControlName="Title" placeholder="{{'Common.title' | translate | headtitlecase}}">
                                </ngx-form-group>
                            </div>
                            <div class="col-md-3">
                                <div class="row">
                                    <div class="col-md-6">
                                        <ngx-form-group [formGroup]="formItem" name="DateOfSale" label="{{validateVoucherDate(formItem.get('DateOfSale'), 'Sales.dateOfSales')}}" [array]="array" [index]="i" [allowCopy]="false" [required]="true" [touchedValidate]="false">
                                            <input type="text" [owlDateTime]="DateOfSale" [owlDateTimeTrigger]="DateOfSale" nbInput fullWidth formControlName="DateOfSale" placeholder="{{'Sales.dateOfSales' | translate | headtitlecase}}">
                                            <owl-date-time #DateOfSale [startAt]="formItem.get('DateOfSale')?.value || this.cms.lastVoucherDate"></owl-date-time>
                                        </ngx-form-group>
                                    </div>
                                    <div class="col-md-6">
                                        <ngx-form-group [formGroup]="formItem" name="RelativeVouchers" label="Common.relationVoucher" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                            <button class="fit-control-button" [outline]="true" nbButton status="primary" fullWidth (click)="openRelativeVoucherChoosedDialog(formItem)" size="medium" hero>
                                                <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>Thêm chứng từ liên quan
                                            </button>
                                        </ngx-form-group>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12" *ngIf="formItem.get('RelativeVouchers').value?.length > 0">
                                <div class="form-group">
                                    <label class="label"><span class="valid">{{'Common.relativeVoucher' | translate | headtitlecase}}: </span></label>
                                    <div>
                                        <span class="tag" *ngFor="let relationVoucher of formItem.get('RelativeVouchers').value" nbTooltip="{{relationVoucher.typeMap?.text}}: {{relationVoucher | objectstext}}">
                                            <nb-icon pack="eva" icon="pricetags-outline" class="icon"></nb-icon><span class="label" (click)="openRelativeVoucher(relationVoucher)">{{relationVoucher.typeMap?.symbol | objectid}}:{{relationVoucher | objectid}}</span>
                                            <nb-icon pack="eva" icon="close-outline" class="close-btn" (click)="removeRelativeVoucher(formItem, relationVoucher)"></nb-icon>
                                        </span>
                                    </div>
                                    <div class="break"></div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-3">
                                <ngx-form-group [formGroup]="formItem" name="Employee" label="Common.employee" [array]="array" [index]="i" [allowCopy]="true" [required]="false">
                                    <ngx-select2 formControlName="Employee" [select2Option]="selectEmployeeOption"></ngx-select2>
                                </ngx-form-group>
                            </div> -->

                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="Note" label="Common.note" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <textarea class="scrollable-container detail" nbInput fullWidth formControlName="Note" placeholder="{{'Common.note' | translate | headtitlecase}}" rows="4" style="height: 5rem"></textarea>
                                </ngx-form-group>
                            </div>
                            <div class="col-sm-12">
                                <ngx-form-group [formGroup]="formItem" name="SubNote" label="Common.subNote" [array]="array" [index]="i" [allowCopy]="false" [required]="false">
                                    <textarea class="scrollable-container detail" nbInput fullWidth formControlName="SubNote" placeholder="{{'Common.subNote' | translate | headtitlecase}}" rows="3" style="height: 5rem"></textarea>
                                </ngx-form-group>
                            </div>
                        </div>

                        <!-- Detail form -->
                        <label class="label label-with-border-below">{{'Voucher.productOrservice' | translate | headtitlecase}}</label>
                        <div class="row dashed-radius-border">
                            <div class="col-sm-12">

                                <div formArrayName="Details" class="form-details" [sortablejs]="getDetails(formItem)" [sortablejsOptions]="{ handle: '.sorting-handle' }">
                                    <div class="form-detail-item" *ngFor="let detail of getDetails(formItem).controls; let ic=index" [formGroup]="detail">
                                        <hr>
                                        <div class="row">
                                            <div class="col-lg-1 col-md-1 col-sm-12 col-is-12">
                                                <div class="row">
                                                    <div class="col-lg-5 col-md-5 col-sm-5 col-is-5">
                                                        <ngx-form-group [hideLabel]="false" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'" [formGroup]="detail" name="No" label="STT" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                                            <input nbInput class="sorting-handle" title="Kéo thả để di chuyển" fullWidth formControlName="No" value="{{ic+1}}" readonly style="text-align: center; cursor: grab; width: 100%; height: 2.5rem; padding: 0;">
                                                            <!-- <input type="hidden" formControlName="Id"> -->
                                                        </ngx-form-group>
                                                        <div class="form-group">
                                                            <label class="label" *ngIf="getFieldValue(detail, 'Type') === 'CATEGORY'">Gở</label>
                                                            <button nbButton status="{{getFieldValue(detail, 'Type') !== 'CATEGORY' ? 'danger' : 'primary'}}" hero size="medium" (click)="removeDetailGroup(formItem, detail, ic)" style="width: 100%; margin-bottom: 3px; padding: 0">
                                                                <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                                            </button>
                                                        </div>
                                                        <div class="form-group" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                            <button nbButton status="primary" hero size="medium" (click)="duplicateDetail(formItem, detail, ic)" style="width: 100%; margin-bottom: 3px; padding: 0" nbTooltip="Copy xuống dưới">
                                                                <nb-icon pack="eva" icon="corner-down-right-outline"></nb-icon>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-7 col-md-7 col-sm-7 col-is-7">
                                                        <ngx-form-group [hideLabel]="false" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'" [formGroup]="detail" name="Image" label="Common.image" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                                            <ngx-files-input formControlName="Image" [config]="{style: {height: '6.9rem', padding: '0'}, thumbnailStype: {height: '5rem', width: '100%'}}"></ngx-files-input>
                                                        </ngx-form-group>
                                                        <div class="form-group" *ngIf="getFieldValue(detail, 'Type') === 'CATEGORY'">
                                                            <label class="label">Di chuyển</label>
                                                            <input type="text" nbInput fullWidth placeholder="Kéo để di chuyển" disabled class="sorting-handle" style="cursor: pointer;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 col-md-10 col-sm-12 col-is-12">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-4 col-sm-12 col-is-12">
                                                        <div class="row">
                                                            <div class="col-lg-{{getFieldValue(detail, 'Type') !== 'CATEGORY' ? '4' : '12'}} col-sm-{{getFieldValue(detail, 'Type') !== 'CATEGORY' ? '3' : '12'}} col-is-12">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Type" label="Common.type" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                                    <ngx-select2 [data]="select2DataForType" formControlName="Type" (selectChange)="toMoney(formItem, detail, 'Type', ic)" [select2Option]="select2OptionForType"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-8 col-md-8 col-sm-9 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Product" label="Common.productOrService" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="trie" [touchedValidate]="false" [customIcons]="getCustomIcons(i + ic)" [option]="{parentForm: formItem}">
                                                                    <ngx-select2 formControlName="Product" [select2Option]="select2OptionForProduct" (selectChange)="onSelectProduct(detail, $event, formItem)"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-9 col-md-8 col-sm-12 col-is-12">
                                                        <div class="row">
                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Quantity" label="Voucher.quantity" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="true" [touchedValidate]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="Quantity" placeholder="{{'Voucher.quantity' | translate | headtitlecase}}" class="align-right" (keyup)="toMoney(formItem, detail, 'Quantity', ic)" [inputMask]="towDigitsInputMask">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Unit" label="Voucher.unit" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="true" [touchedValidate]="false" [customIcons]="unitCustomIcons">
                                                                    <ngx-select2 formControlName="Unit" [data]="detail['UnitList']" [select2Option]="select2OptionForUnit" (selectChange)="onSelectUnit(detail, $event, formItem)"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Price" label="Giá EU" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false" [touchedValidate]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="Price" placeholder="{{'Voucher.price' | translate | headtitlecase}}" class="align-right" (keyup)="toMoney(forxmItem, detail, 'Price', ic)" currencyMask [options]="priceCurencyFormat" (focus)="currencyMaskFocus($event, priceCurencyFormat)" (keydown)="currencyMastKeydown($event, priceCurencyFormat)" (paste)="onPasteNumber($event, priceCurencyFormat)">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="DiscountPercent" label="%CK" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="DiscountPercent" placeholder="{{'%CK' | translate | headtitlecase}}" class="align-right" (keyup)="toMoney(formItem, detail, 'DiscountPercent', ic)" [inputMask]="towDigitsInputMask">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="DiscountPrice" label="Giá CK" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="DiscountPrice" placeholder="Giá CK" class="align-right" currencyMask [options]="toMoneyCurencyFormat" (keyup)="toMoney(formItem, detail, 'DiscountPrice', ic)" (focus)="currencyMaskFocus($event, toMoneyCurencyFormat)" (keydown)="currencyMastKeydown($event, toMoneyCurencyFormat)" (paste)="onPasteNumber($event, toMoneyCurencyFormat)">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="ToMoney" label="Voucher.toMoney" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="ToMoney" placeholder="Thành tiền" class="align-right" currencyMask [options]="toMoneyCurencyFormat" (keyup)="toMoney(formItem, detail, 'ToMoney', ic)" (focus)="currencyMaskFocus($event, toMoneyCurencyFormat)" (keydown)="currencyMastKeydown($event, toMoneyCurencyFormat)" (paste)="onPasteNumber($event, toMoneyCurencyFormat)">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-is-12" *ngIf="getFieldValue(detail, 'Type') === 'CATEGORY'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Description" label="Common.description" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                                    <textarea class="scrollable-container detail" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}" rows="1"></textarea>
                                                                </ngx-form-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-3 col-sm-12 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                        <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Business" label="Common.accountingBusiness" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                            <ngx-select2 [data]="accountingBusinessList" formControlName="Business" (selectChange)="toMoney(formItem, detail, 'Business', ic)" [select2Option]="select2OptionForAccountingBusiness"></ngx-select2>
                                                        </ngx-form-group>
                                                    </div>
                                                    <div class="col-lg-9 col-md-9 col-sm-12 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                        <div class="row">
                                                            <div class="col-md-6 col-sm-6 col-is-6" *ngIf="getFieldValue(detail, 'Type') == 'PRODUCT'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Container" label="Warehouse.container" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                                                    <ngx-select2 [data]="detail['ContainerList'] || []" formControlName="Container" [select2Option]="select2OptionForContainer" status="success"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-sm-2" *ngIf="getFieldValue(detail, 'Type') == 'PARTNERPRODUCT'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Supplier" label="Nhà cung cấp" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                                                                    <ngx-select2 formControlName="Supplier" [select2Option]="select2OptionForDetailSupplier" (selectChange)="onDetailSupplierChange(detail, $event, formItem)" status="info"></ngx-select2>
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-sm-2" *ngIf="getFieldValue(detail, 'Type') == 'PARTNERPRODUCT'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="SupplierAddress" label="Địa chỉ lấy hàng" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                                    <textarea class="scrollable-container detail" nbInput fullWidth formControlName="SupplierAddress" placeholder="{{'Địa chỉ lấy hàng' | translate | headtitlecase}}" rows="1" status="info"></textarea>
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-sm-2" *ngIf="getFieldValue(detail, 'Type') == 'PARTNERPRODUCT'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="PurchasePrice" label="Giá kho" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false" [touchedValidate]="false" [align]="'right'">
                                                                    <input type="text" nbInput fullWidth formControlName="PurchasePrice" placeholder="{{'Giá kho' | translate | headtitlecase}}" class="align-right" currencyMask [options]="priceCurencyFormat" (focus)="currencyMaskFocus($event, priceCurencyFormat)" (keydown)="currencyMastKeydown($event, priceCurencyFormat)" (paste)="onPasteNumber($event, priceCurencyFormat)" status="info">
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-sm-6" *ngIf="getFieldValue(detail, 'Type') == 'PARTNERPRODUCT' || getFieldValue(detail, 'Type') == 'PRODUCT'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Description" label="Common.description" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                                    <textarea class="scrollable-container detail" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}" rows="1"></textarea>
                                                                </ngx-form-group>
                                                            </div>
                                                            <div class="col-sm-12" *ngIf="getFieldValue(detail, 'Type') == 'SERVICE'">
                                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="Description" label="Common.description" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="true" [required]="true" [touchedValidate]="false">
                                                                    <textarea class="scrollable-container detail" nbInput fullWidth formControlName="Description" placeholder="{{'Common.description' | translate | headtitlecase}}" rows="1"></textarea>
                                                                </ngx-form-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-md-12 col-sm-12 col-is-12" *ngIf="getFieldValue(detail, 'Type') !== 'CATEGORY'">
                                                <ngx-form-group [hideLabel]="false" [formGroup]="detail" name="AccessNumbers" label="Số truy xuất" [array]="getDetails(formItem)" [index]="ic" [allowCopy]="false" [required]="false">
                                                    <textarea [attr.disabled]="getFieldValue(detail, 'Type') != 'CATEGORY' && !detail['IsManageByAccessNumber'] ? true : null" class="scrollable-container detail" nbInput fullWidth formControlName="AccessNumbers" [placeholder]="getFieldValue(detail, 'Type') != 'CATEGORY' && detail['IsManageByAccessNumber'] ? 'Yêu cầu quét mã khi xuất kho' : 'Hàng hóa không quản lý theo số truy xuất'" style="height: 110px" #accessNumbers (change)="onSelectAccessNumbers(detail, $event, true, accessNumbers)" (keyup)="onSelectAccessNumbers(detail, $event, false, accessNumbers)"></textarea>
                                                </ngx-form-group>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-4 col-sm-12 col-is-12">
                                            <button nbButton status="success" (click)="addDetailFormGroup(formItem)" size="medium" hero [disabled]="isProcessing">
                                                <nb-icon pack="eva" icon="plus-circle-outline"></nb-icon>{{'Sales.addProduct' | translate | headtitlecase}}
                                            </button>
                                        </div>
                                        <div class="col-lg-3 col-md-8 col-sm-12 col-is-12"></div>
                                        <div class="col-lg-7 col-md-12 col-sm-12 col-is-12">
                                            <div class="row">
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-2 col-sm-4 col-is-4"></div>
                                                <div class="col-md-3 col-sm-4 col-is-4" style="text-align: right; font-weight: bold;">{{'Voucher.total' | translate | headtitlecase}}</div>
                                                <div class="col-md-3 col-sm-8 col-is-8" style="text-align: right; font-weight: bold; padding-right: 1.7rem;">{{formItem.get('_total').value | currency:'VND'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">

                            </div>
                        </div>
                        <!-- End Detail form -->

                    </div>
                </nb-card-body>
                <nb-card-footer>
                    <div class="buttons-row" style="text-align: right;">
                        <button nbButton hero status="primary" (click)="preview([formItem.value])" [disabled]="!form.valid || isProcessing">
                            <nb-icon on b-icon pack="eva" icon="printer"></nb-icon>{{'Common.preview' | translate | headtitlecase}}
                        </button>
                        <button nbButton status="danger" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button>
                        <button nbButton status="success" [disabled]="!form.valid || isProcessing" style="float: right;" hero (click)="save()">{{'Common.save' | translate | headtitlecase}}</button>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </div>
</form>